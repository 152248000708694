'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.units.controller:UnitDevicesParametersCtrl

 # @description

###
class UnitDevicesParametersCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    UnitsManager
    DeviceTypeManager
    $log
    $q
    _
  ) ->
    @progress = true
    @model = {}

    @deviceTypes = []
    @deviceTypeProfiles = []

    @unitDevices = []

    @selectedUnitDevices = []
    @selectedParameters = []

    init = =>
      @id = id
      @load = load
      @cancel = cancel
      @submit = submit

      @load()
    ##########

    load = ->
      DeviceTypeManager.getListPromise().then (responseDeviceTypes) =>
        @deviceTypes = _.values responseDeviceTypes.results
        @deviceTypeProfiles = []

        for type in @deviceTypes
          for profile in _.values type.profiles
            @deviceTypeProfiles.push
              code: "#{type.code}_#{profile.code}"
              parameters: profile.parameters

        UnitsManager.one(@id)
          .then (data) =>
            @unitDevices = data.unitDevices
              .filter (v) -> !v.deletedAt?

            @unitDevices
              .forEach (v) ->
                v._selected = false

            @progress = false
            @form = {}

    @getUnitDeviceParameters = (unitDevice) =>
      deviceTypeProfiles = @deviceTypeProfiles
        .filter (v) ->
          v.code == "#{unitDevice.device.deviceType}_#{unitDevice.deviceTypeProfile}"

      if deviceTypeProfiles.length and deviceTypeProfiles[0].parameters?
        return deviceTypeProfiles[0].parameters

      return []

    @reloadDeviceParameters = =>
      @selectedUnitDevices = @unitDevices.filter (v) -> v._selected
      parameters = {}

      for unitDevice in @selectedUnitDevices
        for param in @getUnitDeviceParameters unitDevice
          if not parameters[param.code]?
            parameters[param.code] = param

      @selectedParameters = _.values parameters

    @getParameterById = (unitDevice, param) ->
      parameter = @getUnitDeviceParameters(unitDevice).filter (v) -> v.code == param.code

      if parameter.length
        value = if unitDevice.parameters[param.code]? then unitDevice.parameters[param.code] else null

        return {
          parameter: param
          value: value
        }

      return null

    cancel = ->
      $mdDialog.cancel()

    submit = ->
      console.log 'submit'

    @saveDeviceParameters = =>
      for unitDevice in @selectedUnitDevices
        parameters = {}

        for param in @selectedParameters
          deviceParam = @getParameterById unitDevice, param

          if not deviceParam?
            continue

          parameters[param.code] = deviceParam.value

          if param._value?
            switch param.type
              when 'integer'
                parameters[param.code] = parseInt(param._value, 10)
              when 'number'
                parameters[param.code] = parseFloat(param._value)
              when 'choice'
                parameters[param.code] = param._value
              when 'text'
                parameters[param.code] = param._value
              when 'file'
                if param._value.data?
                  prefixLength = param._value.data.lastIndexOf(',') + 1
                  parameters[param.code] = param._value.data.substr prefixLength
              else
                parameters[param.code] = param._value

        if Object.keys(parameters).length > 0
          UnitsManager.saveDeviceParameters id, unitDevice.id, parameters

      $mdDialog.cancel()

    init()
angular
  .module('mundoAdmin.units')
  .controller 'UnitDevicesParametersCtrl', UnitDevicesParametersCtrl
